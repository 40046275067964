<template>
  <div class="RedeemTips">
    <div class="tips-logo" />
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="des"
      v-html="$t('tips.Redeem')"
    />
    <div class="share-list contain-RedeemTips">
      <div
        v-for="item in list"
        :key="item.itemId"
        class="contact-div"
      >
        <div class="contact-dev-left">
          <img src="@/assets/img/icon_discord.png">
        </div>
        <div class="contact-dev-right">
          <div class="contact-dev-right-top">
            <div class="contact-dev-right-top-top">
              {{ item.title }}
            </div>
            <a
              class="contact-dev-right-top-bottom firebaseClickEvent"
              :href="item.link"
            >{{ item.link }}</a>
          </div>
        </div>
      </div>

      <!-- <template>
        <div class="contact-div">
          <div class="contact-dev-left">
            <img src="@/assets/img/icon_facebook.png">
          </div>
          <div class="contact-dev-right">
            <div class="contact-dev-right-top">
              <div class="contact-dev-right-top-top">
                {{ FaceBookLinkInfo.title }}
              </div>
              <a
                class="contact-dev-right-top-bottom firebaseClickEvent"
                @click.prevent="handleCallPpp(FaceBookLinkInfo)"
              >{{ FaceBookLinkInfo.link }}</a>
            </div>
          </div>
        </div>
      </template> -->
    </div>
  </div>
</template>

<script>
import CallApp from 'callapp-lib'

// ko韩语 zh中文繁体 en英语 in印尼  th泰语 vi越南语 ms 马来语
const langMaps = {
  in: 2,
  th: 1
}
export default {
  data () {
    return {
      list: [
        // {
        //   title: 'Discord—USA Community',
        //   itemId: 'DiscordUSACommunity',
        //   link: 'https://discord.gg/nPJpxrH'
        // },
        {
          title: 'Discord—Global Community',
          itemId: 'DiscordGlobalCommunity',
          link: 'https://discord.gg/ecT3r7wCA3'
        }
      ],
      FacebookList: [
        {
          title: 'Facebook Global (English)',
          contentType: 'NetBoomUSA',
          itemId: 'NetBoomUSA',
          fbId: 'NetBoom.USA/',
          link: 'https://bit.ly/3h4qoFd'
        },
        {
          title: 'Netboom Thailand ( ไทย )',
          contentType: 'NetBoomThailand',
          itemId: 'NetBoomThailand',
          fbId: '876076196191131',
          link: 'https://bit.ly/2WcYqit'
        },
        {
          title: 'Netboom Indonesia (bahasa Indonesia)',
          contentType: 'NetBoomIndonesia',
          itemId: 'NetBoomIndonesia',
          fbId: '2451291648304101',
          link: 'https://bit.ly/3doIvn9'
        }
      ]
    }
  },
  computed: {
    FaceBookLinkInfo () {
      const index = langMaps[this.$i18n.locale] || 0
      return this.FacebookList[index]
    }
  },
  methods: {
    handleCallPpp (item) {
      const fbFailBack = 'https://www.facebook.com/groups/' + item.fbId
      const openPath = 'group/' + item.fbId
      const option = {
        scheme: {
          protocol: 'fb'
        },
        fallback: fbFailBack,
        timeout: 2000
      }
      const lib = new CallApp(option)
      lib.open({
        path: openPath
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "~@/assets/styles/share-list.less";
  .share-list{
    // background-color: #F4F4F4;
    margin-top: 30px;
    width: 100%;
    .contact-div{
      padding: 20px 0;
    }
    .contact-dev-left{
      width: 142px;
      padding-left: 10px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .contact-dev-left img{
        width: 104px;
        height: 104px;
    }
    .contact-dev-right-top-top{
      height: 60px;
      font-weight: bold;
    }
  }
</style>

<style lang="less" scoped>
  .RedeemTips{
    box-sizing: border-box;
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 264px;
    .tips-logo {
      width: 328px;
      height: 322px;
      background-image: url('~@/assets/img/tips.png');
      background-size: cover;
      margin-bottom: 83px;
    }
    .des{
      padding: 0 35px;
      font-size: 33px;
      line-height: 52px;
    }
  }
</style>
